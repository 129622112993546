import axios from "axios"
import { adminActions } from "../store/store"
import { useDispatch } from 'react-redux'
import { failNotify, SuccessNotify } from "../components/notify"
import Url from "../Data/data"

function useAdmin() {
  // const url = "https://us-central1-cmrgi-transport.cloudfunctions.net/api/admin"
  const url = Url + "/admin"
  
  const dispatch = useDispatch()

  const login = async (details) => {
    dispatch(adminActions.setLoading(true))
    try{
      // console.log(details)
      const res = await axios.post(url + "/login", details)
      localStorage.setItem("token", res.data.tokenAdmin)
      localStorage.setItem("college", details.college)
      localStorage.setItem("role", details.role)
      dispatch(adminActions.setIsAuthenticated(true))
      SuccessNotify("login Successful")
    }catch(err){
      failNotify(err.response.data.message)
    }finally{
      dispatch(adminActions.setLoading(false))
    }
  }

  const updatePassword = async (details) => {
    dispatch(adminActions.setLoading(true))
    try {
      const res = await axios.put(url + "/updatePassword", details);
      SuccessNotify("Password updated successfully");
    } catch (err) {
      failNotify(err.response.data.message);
    } finally {
      dispatch(adminActions.setLoading(false));
    }
  }

  const logout = async (details) => {
    dispatch(adminActions.setLoading(true))
    try{
        // const res = await axios.post(url + "/logout", details)
        localStorage.removeItem("token")
        localStorage.removeItem("college")
        dispatch(adminActions.setIsAuthenticated(false))
    }
    catch(err){
      // console.log(err.response)
        failNotify(err.response.data.message)
    }
    finally{
        dispatch(adminActions.setLoading(false))
    }
  }

  return { login, logout, updatePassword }

 }

export default useAdmin

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { staffActions } from "../store/store";
import { failNotify, SuccessNotify } from "../components/notify";
import Url from "../Data/data";

function useStaff() {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.staffState.staff);
  const url = Url + "/staff";

  const fetchStaff = async (studentDetails = {}) => {
    dispatch(staffActions.setLoading(true));
    try {
      const res = await axios.get(url + "/getStaffDetails", {
        params: studentDetails,
      });
      if (Object.keys(studentDetails).length === 0) {
        dispatch(staffActions.setStaff(res.data.result));
      } else {
        dispatch(staffActions.setSingleStaff(res.data.result));
      }
    } catch (err) {
      failNotify(err.response.data.message);
    } finally {
      dispatch(staffActions.setLoading(false));
    }
  };

  const searchStaffByName = (startLetters, college = "") => {
    if (students && startLetters) {
      const regex = new RegExp(`^${startLetters}`, "i");
      const newArr = students.filter(
        (student) =>
          regex.test(student.studentName) &&
          (college === "" ? true : student.college === college)
      );
      dispatch(staffActions.setTempStaff(newArr));
    } else if (!startLetters) {
      dispatch(
        staffActions.setTempStaff(
          students.filter((student) =>
            college === "" ? true : student.college === college
          )
        )
      );
    }
  };

  const searchStaffByField = (field) => {
    if (students) {
      dispatch(
        staffActions.setTempStaff(
          students.filter(
            (student) =>
              student[Object.keys(field)[0]] === Object.values(field)[0]
          )
        )
      );
    }
  };

  const addStaff = async (studentDetails) => {
    dispatch(staffActions.setLoading(true));
    try {
      const res = await axios.post(url + "/addStaff", studentDetails);
      SuccessNotify(res.data.message);
    } catch (err) {
      failNotify(err.response.data.message);
      dispatch(staffActions.setError(err.response.data.message));
    } finally {
      dispatch(staffActions.setLoading(false));
      await fetchStaff();
    }
  };
  const deleteStaff = async (studentDetails) => {
    dispatch(staffActions.setLoading(true));
    try {
      const res = await axios.post(url + "/deleteStaff", studentDetails);
      SuccessNotify(res.data.message);
      dispatch(staffActions.setStaff(res.data.result));
    } catch (err) {
      failNotify(err.response.data.message);
    } finally {
      dispatch(staffActions.setLoading(false));
      fetchStaff();
    }
  };

  const getStaffByFilter = async (studentDetails) => {
    dispatch(staffActions.setLoading(true));
    try {
      const res = await axios.post(url + "/getStaffByFilter", studentDetails);
      SuccessNotify(res.data.message);
      dispatch(staffActions.setTempStaff(res.data.result));
    } catch (err) {
      failNotify(err.response.data.message);
      dispatch(staffActions.setTempStaff(err.response.data.result));
    } finally {
      dispatch(staffActions.setLoading(false));
    }
  };

  return {
    fetchStaff,
    addStaff,
    deleteStaff,
    searchStaffByName,
    searchStaffByField,
    getStaffByFilter,
  };
}

export default useStaff;

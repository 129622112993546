import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { studentActions } from "../store/store";
import { failNotify, SuccessNotify } from "../components/notify";
import Url from "../Data/data";

function useStudent() {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.studentState.students);
  // const url = "https://us-central1-cmrgi-transport.cloudfunctions.net/api/student"
  const url = Url + "/student";

  const fetchStudents = async (studentDetails = {}) => {
    dispatch(studentActions.setLoading(true));
    try {
      const res = await axios.get(url + "/getStudentDetails", {
        params: studentDetails,
      });
      if (Object.keys(studentDetails).length === 0) {
        dispatch(studentActions.setStudents(res.data.result));
      } else {
        dispatch(studentActions.setStudent(res.data.result));
      }
    } catch (err) {
      failNotify(err.response.data.message);
    } finally {
      dispatch(studentActions.setLoading(false));
    }
  };

  const searchStudentsByName = (startLetters, college = "") => {
    if (students && startLetters) {
      const regex = new RegExp(`^${startLetters}`, "i");
      const newArr = students.filter(
        (student) =>
          regex.test(student.studentName) &&
          (college === "" ? true : student.college === college)
      );
      dispatch(studentActions.setTempStudents(newArr));
    } else if (!startLetters) {
      dispatch(
        studentActions.setTempStudents(
          students.filter((student) =>
            college === "" ? true : student.college === college
          )
        )
      );
    }
  };

  const searchStudentByField = (field) => {
    if (students) {
      dispatch(
        studentActions.setTempStudents(
          students.filter(
            (student) =>
              student[Object.keys(field)[0]] === Object.values(field)[0]
          )
        )
      );
    }
  };

  const addStudent = async (studentDetails) => {
    dispatch(studentActions.setLoading(true));
    try {
      const res = await axios.post(url + "/addStudent", studentDetails);
      SuccessNotify(res.data.message);
    } catch (err) {
      failNotify(err.response.data.message);
      dispatch(studentActions.setError(err.response.data.message));
    } finally {
      dispatch(studentActions.setLoading(false));
      await fetchStudents();
    }
  };
  const deleteStudent = async (studentDetails) => {
    dispatch(studentActions.setLoading(true));
    try {
      const res = await axios.post(url + "/deleteStudent", studentDetails);
      SuccessNotify(res.data.message);
      dispatch(studentActions.setStudents(res.data.result));
    } catch (err) {
      failNotify(err.response.data.message);
    } finally {
      dispatch(studentActions.setLoading(false));
      fetchStudents();
    }
  };

  const getStudentByFilter = async (studentDetails) => {
    dispatch(studentActions.setLoading(true));
    try {
      const res = await axios.post(url + "/getStudentByFilter", studentDetails);
      SuccessNotify(res.data.message);
      dispatch(studentActions.setTempStudents(res.data.result));
    } catch (err) {
      failNotify(err.response.data.message);
      dispatch(studentActions.setTempStudents(err.response.data.result));
    } finally {
      dispatch(studentActions.setLoading(false));
    }
  };

  return {
    fetchStudents,
    addStudent,
    deleteStudent,
    searchStudentsByName,
    searchStudentByField,
    getStudentByFilter,
  };
}

export default useStudent;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Images from "../components/Images";
import Student from "./../components/Student";
import useAdmin from "./../customhooks/useAdmin";
import Image from "../images/BannerImage.png";
import useBus from "../customhooks/useBus";
import useStops from "../customhooks/useStops";
import usePendingStudents from "../customhooks/usePendingStudents";
import Modal from "../components/Modal";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ShowStudentDetails from "../components/ShowStudentDetails";
import FinanceFilter from "../components/FinanceFilter";
import useStaff from "../customhooks/useStaff";
import useStudent from "../customhooks/useStudent";

function AdminPage({ college, admin, role }) {
  const buses = useSelector((state) => state.busState.buses);
  let students = useSelector((state) => state.studentState.students);
  let staff = useSelector((state) => state.staffState.staff);
  if (students === undefined) students = [];
  const { logout } = useAdmin();
  const navigate = useNavigate();
  const [getFilterData, setFilterData] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [requestPage, setRequestPage] = useState(false);
  const [studentStatus, setStudentStatus] = useState(false);
  const [financeFilter, setFinanceFilter] = useState(false);
  const [viewOnlyStaff, setViewOnlyStaff] = useState(false);
  const { fetchStudentsByFilter } = usePendingStudents();
  const allPendingStudents = useSelector(
    (state) => state.pendingStudentState.pendingStudents
  );

  const { fetchStudents } = useStudent();
  const { fetchStaff } = useStaff();

  useEffect(() => {
    fetchStudents();
    fetchStaff();
  }, []);

  // console.log(allPendingStudents)
  const pendingStudents = allPendingStudents.filter(
    (student) => student.status === "pending"
  );
  // console.log(allPendingStudents, pendingStudents)

  useEffect(() => {
    fetchStudentsByFilter({ college });
  }, [requestPage, studentStatus]);
  function handleClick() {
    navigate(`/adminStaff/${college}/home/studentRegistrationForm`);
  }

  function handleLogOut() {
    logout();
  }

  return (
    <div>
      <div className="Banner">
        <Images
          imageLink={Image}
          imageText={"CMRGI Banner"}
          styles={{ height: "120px" }}
        />
      </div>
      <div className="right">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "1em",
            backgroundColor: "#F6821F",
            borderRadius: "10px",
            color: "white",
          }}
        >
          <div className="bannerLeft">
            <h2>
              Welcome to the {role.toUpperCase()} Portal of{" "}
              {college.toUpperCase()} Transport Management
            </h2>
          </div>
          <div className="bannerRight">
            <h2
              className="inputDataFieldsSingle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Total Buses:{" "}
              <span
                style={{ color: "black", width: "fit-content", padding: "5px" }}
              >
                {buses.length !== 0 ? buses.length : 0}
              </span>
            </h2>
            <h2
              className="inputDataFieldsSingle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Seats Filled:{" "}
              <span
                style={{ color: "black", width: "fit-content", padding: "5px" }}
              >
                {/* prettier-ignore */}
                {buses.length !== 0
                  ? students === undefined
                    ? 0
                    : students.length + staff.length
                  : 0}
              </span>
            </h2>
            <h2
              className="inputDataFieldsSingle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Seats Vacant:{" "}
              <span
                style={{ color: "black", width: "fit-content", padding: "5px" }}
              >
                {buses.length !== 0 ? buses.length * 56 - students.length : 0}
              </span>
            </h2>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          {admin === "false" ? (
            <button
              style={{ width: "fit-content", padding: "10px", margin: "10px" }}
              onClick={handleClick}
            >
              Add Student
            </button>
          ) : (
            <></>
          )}
          {admin === "false" ? (
            <button
              style={{ width: "fit-content", padding: "10px", margin: "10px" }}
              onClick={() =>
                navigate(`/adminStaff/${college}/home/staffRegistrationForm`)
              }
            >
              Add Employee
            </button>
          ) : (
            <></>
          )}
          <button
            style={{ width: "fit-content", padding: "10px", margin: "10px" }}
            onClick={() => {
              setRequestPage(false);
              setViewOnly(false);
              setFilterData(false);
              setStudentStatus(true);
              setFinanceFilter(false);
              setViewOnlyStaff(false);
            }}
          >
            Student Status
          </button>
          <button
            style={{ width: "fit-content", padding: "10px", margin: "10px" }}
            onClick={() => {
              setViewOnly((prevState) => !prevState);
              setFilterData(false);
              setRequestPage(false);
              setStudentStatus(false);
              setFinanceFilter(false);
              setViewOnlyStaff(false);
            }}
          >
            View Students
          </button>
          <button
            style={{ width: "fit-content", padding: "10px", margin: "10px" }}
            onClick={() => {
              setViewOnly(false);
              setFilterData(false);
              setRequestPage(false);
              setStudentStatus(false);
              setFinanceFilter(false);
              setViewOnlyStaff(true);
            }}
          >
            View Staff
          </button>
          {getFilterData ? (
            <button
              style={{ width: "fit-content", padding: "10px", margin: "10px" }}
              onClick={() => {
                setFilterData(false);
                setViewOnly(false);
                setRequestPage(false);
                setStudentStatus(false);
                setFinanceFilter(false);
                setViewOnlyStaff(false);
              }}
            >
              Hide Student Data
            </button>
          ) : (
            <button
              style={{ width: "fit-content", padding: "10px", margin: "10px" }}
              onClick={() => {
                setFilterData(true);
                setViewOnly(false);
                setRequestPage(false);
                setStudentStatus(false);
                setFinanceFilter(false);
                setViewOnlyStaff(false);
              }}
            >
              Generate Student Data
            </button>
          )}
          {admin === "true" ? (
            <button
              style={{ width: "fit-content", padding: "10px", margin: "10px" }}
              onClick={() => {
                setRequestPage(false);
                setViewOnly(false);
                setFilterData(false);
                setStudentStatus(false);
                setFinanceFilter(true);
                setViewOnlyStaff(false);
              }}
            >
              Finance Data
            </button>
          ) : (
            <></>
          )}
          {admin === "true" ? (
            <button
              style={{ width: "fit-content", padding: "10px", margin: "10px" }}
              onClick={() => {
                setRequestPage(true);
                setViewOnly(false);
                setFilterData(false);
                setStudentStatus(false);
                setFinanceFilter(false);
                setViewOnlyStaff(false);
              }}
            >
              Pending Requests
            </button>
          ) : (
            <></>
          )}
          <button
            style={{ width: "fit-content", padding: "10px", margin: "10px" }}
            onClick={handleLogOut}
          >
            LogOut
          </button>
        </div>
        {viewOnlyStaff ? (
          <Student
            adminPage={true}
            collegeSent={college}
            viewOnly={true}
            teacher={true}
          />
        ) : (
          <div></div>
        )}

        {studentStatus ? (
          <div>
            {allPendingStudents.length === 0 ? (
              <h1 style={{ textAlign: "center", margin: "20px 0px" }}>
                No Pending Student
              </h1>
            ) : (
              allPendingStudents.map((student, index) => (
                <PendingStudentTile
                  status={true}
                  student={student}
                  index={index}
                  key={student._id}
                />
              ))
            )}
          </div>
        ) : (
          <div></div>
        )}
        {getFilterData ? (
          <Student adminPage={true} collegeSent={college} viewOnly={false} />
        ) : (
          <div></div>
        )}
        {viewOnly ? (
          <Student adminPage={true} collegeSent={college} viewOnly={true} />
        ) : (
          <div></div>
        )}
        {financeFilter ? <FinanceFilter college={college} /> : <div></div>}
        {requestPage ? (
          <div>
            {pendingStudents.length === 0 ? (
              <h1 style={{ textAlign: "center", margin: "20px 0px" }}>
                No Pending Student
              </h1>
            ) : (
              pendingStudents.map((student, index) => (
                <PendingStudentTile
                  status={false}
                  student={student}
                  index={index}
                  key={student._id}
                  college={college}
                />
              ))
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

function PendingStudentTile({ college, student, index, status }) {
  const { converIdToBus } = useBus();
  const { convertIdToStop } = useStops();
  const busData = converIdToBus(student["routeNo"][0])[0];
  const routeNo = busData ? busData["busName"] : "";
  const stageData = convertIdToStop(student["stage"][0])[0];
  const stage = stageData ? stageData["boardingPoint"] : "";
  let statusStyles;
  if (student.status === "accepted") {
    statusStyles = {
      textAlign: "center",
      fontSize: "18px",
      color: "rgba(1, 50, 32, 1)",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      borderRadius: "5px",
      width: "fit-content",
      padding: "5px 8px",
    };
  } else if (student.status === "rejected") {
    statusStyles = {
      textAlign: "center",
      fontSize: "18px",
      color: "rgba(255, 0, 0, 1)",
      backgroundColor: "rgba(255, 0, 0, 0.3)",
      borderRadius: "5px",
      width: "fit-content",
      padding: "5px 8px",
    };
  } else if (student.status === "pending") {
    statusStyles = {
      textAlign: "center",
      fontSize: "18px",
      color: "rgba(0, 0, 0, 1)",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "5px",
      width: "fit-content",
      padding: "5px 8px",
    };
  }

  const studentDetailsShowData = {};
  for (const heading of Object.keys(student)) {
    if (heading === "__v" || heading === "file") continue;
    else if (heading === "routeNo")
      studentDetailsShowData["routeNo"] = student["routeNo"][0];
    else if (heading === "stage")
      studentDetailsShowData["stage"] = student["stage"][0];
    else studentDetailsShowData[heading] = student[heading];
  }
  const [editOpen, setEditOpen] = useState(false);
  const headings = [
    "SNo",
    "rollNo",
    "department",
    "studentName",
    "routeNo",
    "stage",
    "phoneNumber",
    "amountPaid",
  ];

  if (student.amountPaid === 45000 || student.amountPaid === 55000)
    return <></>;
  return (
    <div
      className="row"
      key={student._id}
      style={{ padding: "10px", minHeight: "100px" }}
    >
      {headings.map((heading) => (
        <div
          key={heading}
          style={{
            fontSize: "20px",
            textAlign: "left",
            justifyContent: "flex-start",
          }}
        >
          {heading === "SNo"
            ? index + 1
            : heading === "routeNo" || heading === "stage"
            ? heading === "routeNo"
              ? routeNo
              : stage
            : student[heading]}
        </div>
      ))}
      {status ? (
        <p style={statusStyles}>{student.status?.toUpperCase()}</p>
      ) : (
        <>
          <td>
            <div
              className="buttons"
              onClick={() => setEditOpen(true)}
              style={{ color: "black" }}
            >
              <DoubleArrowIcon sx={{ fontSize: 25 }} />
            </div>
          </td>
          <Modal open={editOpen} onClose={() => setEditOpen(false)}>
            <ShowStudentDetails
              approval={true}
              studentDetails={studentDetailsShowData}
              show={true}
              photo={student["file"]}
              submit={() => setEditOpen(false)}
              college={college}
            />
          </Modal>
        </>
      )}
    </div>
  );
}

export default AdminPage;

const Url = "https://us-central1-cmrgi-transport.cloudfunctions.net/api";
// const Url = "http://localhost:3001"
export default Url;
// const data = [
//     {
//         cluster: "1",
//         clusterName: "Towards Bollarm, Alwal, ECIL",
//         busRoutes:[
//             {
//                 busNo: "1",
//                 busName: "Rampally X Road",
//                 driver: "NagaSulochana",
//                 driverNo: 8897190123,
//                 routeAndTimings: [{
//                         bpNo: "101",
//                         boardingPoint: "Rampally X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "102",
//                         boardingPoint: "Nagaram petrol bunk",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "103",
//                         boardingPoint: "Viajaya hospital",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "104",
//                         boardingPoint: "Chakripuram",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "105",
//                         boardingPoint: "Nagarjuna Colony",
//                         time: "7:50"
//                     },

//                 ]
//             },
//             {
//                 busNo: "1A",
//                 busName: "Kushaiguda",
//                 driver: "G.Laxmi",
//                 driverNo: 9963127930,
//                 routeAndTimings: [{
//                         bpNo: "1A01",
//                         boardingPoint: "Kushaiguda(DMart)",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "1A02",
//                         boardingPoint: "Shopping complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A03",
//                         boardingPoint: "Nirmal Nagar",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A04",
//                         boardingPoint: "Yapral water tank",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A05",
//                         boardingPoint: "Yapral Bus stop",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A06",
//                         boardingPoint: "Yapral(Militry Gate)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A07",
//                         boardingPoint: "Priyadharshini Enclave",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A08",
//                         boardingPoint: "Risala bazar",
//                         time: "8:05"
//                     },
//                 ]
//             },
//             {
//                 busNo: "2",
//                 busName: "Nagaram",
//                 driver: "Suguna",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "201",
//                         boardingPoint: "Nagaram Water Tank",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "202",
//                         boardingPoint: "Vayushakthi Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "203",
//                         boardingPoint: "P.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "204",
//                         boardingPoint: "Dammaiguda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "205",
//                         boardingPoint: "Sai Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "206",
//                         boardingPoint: "Saketh Town",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "207",
//                         boardingPoint: "Paremala Nagar",
//                         time: "7:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "2A",
//                 busName: "Saket",
//                 driver: "Sunil Kumar",
//                 driverNo: 9855590258,
//                 routeAndTimings: [{
//                         bpNo: "2A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "2A02",
//                         boardingPoint: "Jammaigadda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "2A03",
//                         boardingPoint: "Yella reddy guda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A04",
//                         boardingPoint: "Dhabha garden",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A05",
//                         boardingPoint: "kapra Munsipal office",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A06",
//                         boardingPoint: "Radhika School",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A07",
//                         boardingPoint: "Nethaji nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A08",
//                         boardingPoint: "Kandhiguda",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "2A09",
//                         boardingPoint: "Kowkur darga",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A10",
//                         boardingPoint: "Janapriya",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A11",
//                         boardingPoint: "Kowkur BusStop",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "2A12",
//                         boardingPoint: "Hakimpet",
//                         time: "8:05"
//                     },
//                     {
//                         bpNo: "2A13",
//                         boardingPoint: "Yamjal(Telangana Paper)",
//                         time: "8:10"
//                     },
//                 ]
//             },
//             {
//                 busNo: "3",
//                 busName: "Radhika Bus Stop",
//                 driver: "ES Babu",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "301",
//                         boardingPoint: "Radhika",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "301",
//                         boardingPoint: "Lal Bazar(Petrol Bunk)",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "303",
//                         boardingPoint: "Teachers Colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "304",
//                         boardingPoint: "Kanajiguda",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "305",
//                         boardingPoint: "Green Fields(DMart)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "306",
//                         boardingPoint: "ST. Anns School",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "307",
//                         boardingPoint: "Diary Form",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "3A",
//                 busName: "Kushaiguda Bus stop",
//                 driver: "P.Sirisha",
//                 driverNo: 995988325,
//                 routeAndTimings: [{
//                         bpNo: "3A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "3A02",
//                         boardingPoint: "A.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "3A03",
//                         boardingPoint: "Neredmet",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "3A04",
//                         boardingPoint: "G.K colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "3A05",
//                         boardingPoint: "R.K Puram",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "3A06",
//                         boardingPoint: "Lothukunta Dharga",
//                         time: "8:00"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4",
//                 busName: "Charlapally",
//                 driver: "A.Ramesh",
//                 driverNo: 9491868404,
//                 routeAndTimings: [{
//                         bpNo: "401",
//                         boardingPoint: "Charlapally(EC Nagar Kaman)",
//                         time: "7:15"
//                     },
//                     {
//                         bpNo: "402",
//                         boardingPoint: "ICOM Bus Stop",
//                         time: "7:20"
//                     },
//                     {
//                         bpNo: "403",
//                         boardingPoint: "Ashok Nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "404",
//                         boardingPoint: "HB Colony NTR Statue",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "405",
//                         boardingPoint: "HB Colony E-Seva",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "406",
//                         boardingPoint: "Moulaali",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "407",
//                         boardingPoint: "Lothukunta HDFC Bank",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "408",
//                         boardingPoint: "Kompally",
//                         time: "8:15"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4A",
//                 busName: "ZTS",
//                 driver: "UdayKiran",
//                 driverNo: 9052098540,
//                 routeAndTimings: [{
//                         bpNo: "4A01",
//                         boardingPoint: "ZTS CocaCola",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A02",
//                         boardingPoint: "NMDC",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A03",
//                         boardingPoint: "East Anand Bagh",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A04",
//                         boardingPoint: "Anand bagh X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A05",
//                         boardingPoint: "Shafilguda X Road",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A06",
//                         boardingPoint: "Krupa Complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A07",
//                         boardingPoint: "Vinayak Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "4A08",
//                         boardingPoint: "Brindavan Colony(Bollurum)",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5",
//                 busName: "ECIL",
//                 driver: "A.Sowjanya",
//                 driverNo: 9642754545,
//                 routeAndTimings: [{
//                         bpNo: "501",
//                         boardingPoint: "ECIL X Road",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "502",
//                         boardingPoint: "Sharadha Hospital",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "503",
//                         boardingPoint: "Alwal Mee Seva",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "504",
//                         boardingPoint: "Ambedkar Statue",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "505",
//                         boardingPoint: "IG STatue",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "506",
//                         boardingPoint: "Narayana JR College",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "507",
//                         boardingPoint: "Loyola College",
//                         time: "8:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5A",
//                 busName: "ECIL",
//                 driver: "Padmapriya",
//                 driverNo: 8886567836,
//                 routeAndTimings: [{
//                         bpNo: "5A01",
//                         boardingPoint: "Sharada BusStop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "5A02",
//                         boardingPoint: "Vayupuri",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "5A03",
//                         boardingPoint: "Lothukunta Railway Bridge",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "5A04",
//                         boardingPoint: "Temple Alwal",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "5A05",
//                         boardingPoint: "More SuperMarket",
//                         time: "7:55"
//                     }
//                 ]
//             },
//             {
//                 busNo: "6",
//                 busName: "Lalapet",
//                 driver: "B.Sinduja",
//                 driverNo: 628110303,
//                 routeAndTimings: [{
//                         bpNo: "601",
//                         boardingPoint: "Lalapet Busstop",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "602",
//                         boardingPoint: "Shanti nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "603",
//                         boardingPoint: "Noori Panshop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "604",
//                         boardingPoint: "Mirjala Guda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "605",
//                         boardingPoint: "Sairam Theater",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "606",
//                         boardingPoint: "Anutex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "607",
//                         boardingPoint: "Uttam Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "608",
//                         boardingPoint: "Ghanshyam",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "609",
//                         boardingPoint: "Picket",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "610",
//                         boardingPoint: "Kharkhana",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "611",
//                         boardingPoint: "Tirumalagiri police station",
//                         time: "8:00"
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         cluster: "2",
//         clusterName: "Towards Bollarm, Alwal, ECIL",
//         busRoutes:[
//             {
//                 busNo: "1",
//                 busName: "Rampally X Road",
//                 driver: "NagaSulochana",
//                 driverNo: 8897190123,
//                 routeAndTimings: [{
//                         bpNo: "101",
//                         boardingPoint: "Rampally X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "102",
//                         boardingPoint: "Nagaram petrol bunk",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "103",
//                         boardingPoint: "Viajaya hospital",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "104",
//                         boardingPoint: "Chakripuram",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "105",
//                         boardingPoint: "Nagarjuna Colony",
//                         time: "7:50"
//                     },

//                 ]
//             },
//             {
//                 busNo: "1A",
//                 busName: "Kushaiguda",
//                 driver: "G.Laxmi",
//                 driverNo: 9963127930,
//                 routeAndTimings: [{
//                         bpNo: "1A01",
//                         boardingPoint: "Kushaiguda(DMart)",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "1A02",
//                         boardingPoint: "Shopping complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A03",
//                         boardingPoint: "Nirmal Nagar",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A04",
//                         boardingPoint: "Yapral water tank",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A05",
//                         boardingPoint: "Yapral Bus stop",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A06",
//                         boardingPoint: "Yapral(Militry Gate)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A07",
//                         boardingPoint: "Priyadharshini Enclave",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A08",
//                         boardingPoint: "Risala bazar",
//                         time: "8:05"
//                     },
//                 ]
//             },
//             {
//                 busNo: "2",
//                 busName: "Nagaram",
//                 driver: "Suguna",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "201",
//                         boardingPoint: "Nagaram Water Tank",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "202",
//                         boardingPoint: "Vayushakthi Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "203",
//                         boardingPoint: "P.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "204",
//                         boardingPoint: "Dammaiguda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "205",
//                         boardingPoint: "Sai Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "206",
//                         boardingPoint: "Saketh Town",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "207",
//                         boardingPoint: "Paremala Nagar",
//                         time: "7:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "2A",
//                 busName: "Saket",
//                 driver: "Sunil Kumar",
//                 driverNo: 9855590258,
//                 routeAndTimings: [{
//                         bpNo: "2A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "2A02",
//                         boardingPoint: "Jammaigadda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "2A03",
//                         boardingPoint: "Yella reddy guda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A04",
//                         boardingPoint: "Dhabha garden",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A05",
//                         boardingPoint: "kapra Munsipal office",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A06",
//                         boardingPoint: "Radhika School",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A07",
//                         boardingPoint: "Nethaji nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A08",
//                         boardingPoint: "Kandhiguda",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "2A09",
//                         boardingPoint: "Kowkur darga",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A10",
//                         boardingPoint: "Janapriya",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A11",
//                         boardingPoint: "Kowkur BusStop",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "2A12",
//                         boardingPoint: "Hakimpet",
//                         time: "8:05"
//                     },
//                     {
//                         bpNo: "2A13",
//                         boardingPoint: "Yamjal(Telangana Paper)",
//                         time: "8:10"
//                     },
//                 ]
//             },
//             {
//                 busNo: "3",
//                 busName: "Radhika Bus Stop",
//                 driver: "ES Babu",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "301",
//                         boardingPoint: "Radhika",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "301",
//                         boardingPoint: "Lal Bazar(Petrol Bunk)",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "303",
//                         boardingPoint: "Teachers Colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "304",
//                         boardingPoint: "Kanajiguda",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "305",
//                         boardingPoint: "Green Fields(DMart)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "306",
//                         boardingPoint: "ST. Anns School",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "307",
//                         boardingPoint: "Diary Form",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "3A",
//                 busName: "Kushaiguda Bus stop",
//                 driver: "P.Sirisha",
//                 driverNo: 995988325,
//                 routeAndTimings: [{
//                         bpNo: "3A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "3A02",
//                         boardingPoint: "A.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "3A03",
//                         boardingPoint: "Neredmet",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "3A04",
//                         boardingPoint: "G.K colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "3A05",
//                         boardingPoint: "R.K Puram",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "3A06",
//                         boardingPoint: "Lothukunta Dharga",
//                         time: "8:00"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4",
//                 busName: "Charlapally",
//                 driver: "A.Ramesh",
//                 driverNo: 9491868404,
//                 routeAndTimings: [{
//                         bpNo: "401",
//                         boardingPoint: "Charlapally(EC Nagar Kaman)",
//                         time: "7:15"
//                     },
//                     {
//                         bpNo: "402",
//                         boardingPoint: "ICOM Bus Stop",
//                         time: "7:20"
//                     },
//                     {
//                         bpNo: "403",
//                         boardingPoint: "Ashok Nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "404",
//                         boardingPoint: "HB Colony NTR Statue",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "405",
//                         boardingPoint: "HB Colony E-Seva",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "406",
//                         boardingPoint: "Moulaali",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "407",
//                         boardingPoint: "Lothukunta HDFC Bank",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "408",
//                         boardingPoint: "Kompally",
//                         time: "8:15"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4A",
//                 busName: "ZTS",
//                 driver: "UdayKiran",
//                 driverNo: 9052098540,
//                 routeAndTimings: [{
//                         bpNo: "4A01",
//                         boardingPoint: "ZTS CocaCola",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A02",
//                         boardingPoint: "NMDC",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A03",
//                         boardingPoint: "East Anand Bagh",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A04",
//                         boardingPoint: "Anand bagh X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A05",
//                         boardingPoint: "Shafilguda X Road",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A06",
//                         boardingPoint: "Krupa Complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A07",
//                         boardingPoint: "Vinayak Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "4A08",
//                         boardingPoint: "Brindavan Colony(Bollurum)",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5",
//                 busName: "ECIL",
//                 driver: "A.Sowjanya",
//                 driverNo: 9642754545,
//                 routeAndTimings: [{
//                         bpNo: "501",
//                         boardingPoint: "ECIL X Road",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "502",
//                         boardingPoint: "Sharadha Hospital",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "503",
//                         boardingPoint: "Alwal Mee Seva",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "504",
//                         boardingPoint: "Ambedkar Statue",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "505",
//                         boardingPoint: "IG STatue",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "506",
//                         boardingPoint: "Narayana JR College",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "507",
//                         boardingPoint: "Loyola College",
//                         time: "8:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5A",
//                 busName: "ECIL",
//                 driver: "Padmapriya",
//                 driverNo: 8886567836,
//                 routeAndTimings: [{
//                         bpNo: "5A01",
//                         boardingPoint: "Sharada BusStop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "5A02",
//                         boardingPoint: "Vayupuri",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "5A03",
//                         boardingPoint: "Lothukunta Railway Bridge",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "5A04",
//                         boardingPoint: "Temple Alwal",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "5A05",
//                         boardingPoint: "More SuperMarket",
//                         time: "7:55"
//                     }
//                 ]
//             },
//             {
//                 busNo: "6",
//                 busName: "Lalapet",
//                 driver: "B.Sinduja",
//                 driverNo: 628110303,
//                 routeAndTimings: [{
//                         bpNo: "601",
//                         boardingPoint: "Lalapet Busstop",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "602",
//                         boardingPoint: "Shanti nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "603",
//                         boardingPoint: "Noori Panshop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "604",
//                         boardingPoint: "Mirjala Guda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "605",
//                         boardingPoint: "Sairam Theater",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "606",
//                         boardingPoint: "Anutex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "607",
//                         boardingPoint: "Uttam Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "608",
//                         boardingPoint: "Ghanshyam",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "609",
//                         boardingPoint: "Picket",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "610",
//                         boardingPoint: "Kharkhana",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "611",
//                         boardingPoint: "Tirumalagiri police station",
//                         time: "8:00"
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         cluster: "3",
//         clusterName: "Towards Bollarm, Alwal, ECIL",
//         busRoutes:[
//             {
//                 busNo: "1",
//                 busName: "Rampally X Road",
//                 driver: "NagaSulochana",
//                 driverNo: 8897190123,
//                 routeAndTimings: [{
//                         bpNo: "101",
//                         boardingPoint: "Rampally X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "102",
//                         boardingPoint: "Nagaram petrol bunk",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "103",
//                         boardingPoint: "Viajaya hospital",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "104",
//                         boardingPoint: "Chakripuram",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "105",
//                         boardingPoint: "Nagarjuna Colony",
//                         time: "7:50"
//                     },

//                 ]
//             },
//             {
//                 busNo: "1A",
//                 busName: "Kushaiguda",
//                 driver: "G.Laxmi",
//                 driverNo: 9963127930,
//                 routeAndTimings: [{
//                         bpNo: "1A01",
//                         boardingPoint: "Kushaiguda(DMart)",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "1A02",
//                         boardingPoint: "Shopping complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A03",
//                         boardingPoint: "Nirmal Nagar",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A04",
//                         boardingPoint: "Yapral water tank",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A05",
//                         boardingPoint: "Yapral Bus stop",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A06",
//                         boardingPoint: "Yapral(Militry Gate)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A07",
//                         boardingPoint: "Priyadharshini Enclave",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A08",
//                         boardingPoint: "Risala bazar",
//                         time: "8:05"
//                     },
//                 ]
//             },
//             {
//                 busNo: "2",
//                 busName: "Nagaram",
//                 driver: "Suguna",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "201",
//                         boardingPoint: "Nagaram Water Tank",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "202",
//                         boardingPoint: "Vayushakthi Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "203",
//                         boardingPoint: "P.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "204",
//                         boardingPoint: "Dammaiguda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "205",
//                         boardingPoint: "Sai Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "206",
//                         boardingPoint: "Saketh Town",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "207",
//                         boardingPoint: "Paremala Nagar",
//                         time: "7:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "2A",
//                 busName: "Saket",
//                 driver: "Sunil Kumar",
//                 driverNo: 9855590258,
//                 routeAndTimings: [{
//                         bpNo: "2A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "2A02",
//                         boardingPoint: "Jammaigadda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "2A03",
//                         boardingPoint: "Yella reddy guda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A04",
//                         boardingPoint: "Dhabha garden",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A05",
//                         boardingPoint: "kapra Munsipal office",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A06",
//                         boardingPoint: "Radhika School",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A07",
//                         boardingPoint: "Nethaji nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A08",
//                         boardingPoint: "Kandhiguda",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "2A09",
//                         boardingPoint: "Kowkur darga",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A10",
//                         boardingPoint: "Janapriya",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A11",
//                         boardingPoint: "Kowkur BusStop",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "2A12",
//                         boardingPoint: "Hakimpet",
//                         time: "8:05"
//                     },
//                     {
//                         bpNo: "2A13",
//                         boardingPoint: "Yamjal(Telangana Paper)",
//                         time: "8:10"
//                     },
//                 ]
//             },
//             {
//                 busNo: "3",
//                 busName: "Radhika Bus Stop",
//                 driver: "ES Babu",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "301",
//                         boardingPoint: "Radhika",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "301",
//                         boardingPoint: "Lal Bazar(Petrol Bunk)",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "303",
//                         boardingPoint: "Teachers Colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "304",
//                         boardingPoint: "Kanajiguda",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "305",
//                         boardingPoint: "Green Fields(DMart)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "306",
//                         boardingPoint: "ST. Anns School",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "307",
//                         boardingPoint: "Diary Form",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "3A",
//                 busName: "Kushaiguda Bus stop",
//                 driver: "P.Sirisha",
//                 driverNo: 995988325,
//                 routeAndTimings: [{
//                         bpNo: "3A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "3A02",
//                         boardingPoint: "A.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "3A03",
//                         boardingPoint: "Neredmet",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "3A04",
//                         boardingPoint: "G.K colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "3A05",
//                         boardingPoint: "R.K Puram",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "3A06",
//                         boardingPoint: "Lothukunta Dharga",
//                         time: "8:00"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4",
//                 busName: "Charlapally",
//                 driver: "A.Ramesh",
//                 driverNo: 9491868404,
//                 routeAndTimings: [{
//                         bpNo: "401",
//                         boardingPoint: "Charlapally(EC Nagar Kaman)",
//                         time: "7:15"
//                     },
//                     {
//                         bpNo: "402",
//                         boardingPoint: "ICOM Bus Stop",
//                         time: "7:20"
//                     },
//                     {
//                         bpNo: "403",
//                         boardingPoint: "Ashok Nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "404",
//                         boardingPoint: "HB Colony NTR Statue",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "405",
//                         boardingPoint: "HB Colony E-Seva",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "406",
//                         boardingPoint: "Moulaali",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "407",
//                         boardingPoint: "Lothukunta HDFC Bank",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "408",
//                         boardingPoint: "Kompally",
//                         time: "8:15"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4A",
//                 busName: "ZTS",
//                 driver: "UdayKiran",
//                 driverNo: 9052098540,
//                 routeAndTimings: [{
//                         bpNo: "4A01",
//                         boardingPoint: "ZTS CocaCola",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A02",
//                         boardingPoint: "NMDC",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A03",
//                         boardingPoint: "East Anand Bagh",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A04",
//                         boardingPoint: "Anand bagh X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A05",
//                         boardingPoint: "Shafilguda X Road",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A06",
//                         boardingPoint: "Krupa Complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A07",
//                         boardingPoint: "Vinayak Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "4A08",
//                         boardingPoint: "Brindavan Colony(Bollurum)",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5",
//                 busName: "ECIL",
//                 driver: "A.Sowjanya",
//                 driverNo: 9642754545,
//                 routeAndTimings: [{
//                         bpNo: "501",
//                         boardingPoint: "ECIL X Road",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "502",
//                         boardingPoint: "Sharadha Hospital",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "503",
//                         boardingPoint: "Alwal Mee Seva",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "504",
//                         boardingPoint: "Ambedkar Statue",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "505",
//                         boardingPoint: "IG STatue",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "506",
//                         boardingPoint: "Narayana JR College",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "507",
//                         boardingPoint: "Loyola College",
//                         time: "8:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5A",
//                 busName: "ECIL",
//                 driver: "Padmapriya",
//                 driverNo: 8886567836,
//                 routeAndTimings: [{
//                         bpNo: "5A01",
//                         boardingPoint: "Sharada BusStop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "5A02",
//                         boardingPoint: "Vayupuri",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "5A03",
//                         boardingPoint: "Lothukunta Railway Bridge",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "5A04",
//                         boardingPoint: "Temple Alwal",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "5A05",
//                         boardingPoint: "More SuperMarket",
//                         time: "7:55"
//                     }
//                 ]
//             },
//             {
//                 busNo: "6",
//                 busName: "Lalapet",
//                 driver: "B.Sinduja",
//                 driverNo: 628110303,
//                 routeAndTimings: [{
//                         bpNo: "601",
//                         boardingPoint: "Lalapet Busstop",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "602",
//                         boardingPoint: "Shanti nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "603",
//                         boardingPoint: "Noori Panshop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "604",
//                         boardingPoint: "Mirjala Guda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "605",
//                         boardingPoint: "Sairam Theater",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "606",
//                         boardingPoint: "Anutex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "607",
//                         boardingPoint: "Uttam Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "608",
//                         boardingPoint: "Ghanshyam",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "609",
//                         boardingPoint: "Picket",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "610",
//                         boardingPoint: "Kharkhana",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "611",
//                         boardingPoint: "Tirumalagiri police station",
//                         time: "8:00"
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         cluster: "4",
//         clusterName: "Towards Bollarm, Alwal, ECIL",
//         busRoutes:[
//             {
//                 busNo: "1",
//                 busName: "Rampally X Road",
//                 driver: "NagaSulochana",
//                 driverNo: 8897190123,
//                 routeAndTimings: [{
//                         bpNo: "101",
//                         boardingPoint: "Rampally X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "102",
//                         boardingPoint: "Nagaram petrol bunk",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "103",
//                         boardingPoint: "Viajaya hospital",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "104",
//                         boardingPoint: "Chakripuram",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "105",
//                         boardingPoint: "Nagarjuna Colony",
//                         time: "7:50"
//                     },

//                 ]
//             },
//             {
//                 busNo: "1A",
//                 busName: "Kushaiguda",
//                 driver: "G.Laxmi",
//                 driverNo: 9963127930,
//                 routeAndTimings: [{
//                         bpNo: "1A01",
//                         boardingPoint: "Kushaiguda(DMart)",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "1A02",
//                         boardingPoint: "Shopping complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A03",
//                         boardingPoint: "Nirmal Nagar",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A04",
//                         boardingPoint: "Yapral water tank",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A05",
//                         boardingPoint: "Yapral Bus stop",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A06",
//                         boardingPoint: "Yapral(Militry Gate)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A07",
//                         boardingPoint: "Priyadharshini Enclave",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A08",
//                         boardingPoint: "Risala bazar",
//                         time: "8:05"
//                     },
//                 ]
//             },
//             {
//                 busNo: "2",
//                 busName: "Nagaram",
//                 driver: "Suguna",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "201",
//                         boardingPoint: "Nagaram Water Tank",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "202",
//                         boardingPoint: "Vayushakthi Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "203",
//                         boardingPoint: "P.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "204",
//                         boardingPoint: "Dammaiguda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "205",
//                         boardingPoint: "Sai Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "206",
//                         boardingPoint: "Saketh Town",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "207",
//                         boardingPoint: "Paremala Nagar",
//                         time: "7:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "2A",
//                 busName: "Saket",
//                 driver: "Sunil Kumar",
//                 driverNo: 9855590258,
//                 routeAndTimings: [{
//                         bpNo: "2A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "2A02",
//                         boardingPoint: "Jammaigadda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "2A03",
//                         boardingPoint: "Yella reddy guda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A04",
//                         boardingPoint: "Dhabha garden",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A05",
//                         boardingPoint: "kapra Munsipal office",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A06",
//                         boardingPoint: "Radhika School",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A07",
//                         boardingPoint: "Nethaji nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A08",
//                         boardingPoint: "Kandhiguda",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "2A09",
//                         boardingPoint: "Kowkur darga",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A10",
//                         boardingPoint: "Janapriya",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A11",
//                         boardingPoint: "Kowkur BusStop",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "2A12",
//                         boardingPoint: "Hakimpet",
//                         time: "8:05"
//                     },
//                     {
//                         bpNo: "2A13",
//                         boardingPoint: "Yamjal(Telangana Paper)",
//                         time: "8:10"
//                     },
//                 ]
//             },
//             {
//                 busNo: "3",
//                 busName: "Radhika Bus Stop",
//                 driver: "ES Babu",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "301",
//                         boardingPoint: "Radhika",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "301",
//                         boardingPoint: "Lal Bazar(Petrol Bunk)",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "303",
//                         boardingPoint: "Teachers Colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "304",
//                         boardingPoint: "Kanajiguda",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "305",
//                         boardingPoint: "Green Fields(DMart)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "306",
//                         boardingPoint: "ST. Anns School",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "307",
//                         boardingPoint: "Diary Form",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "3A",
//                 busName: "Kushaiguda Bus stop",
//                 driver: "P.Sirisha",
//                 driverNo: 995988325,
//                 routeAndTimings: [{
//                         bpNo: "3A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "3A02",
//                         boardingPoint: "A.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "3A03",
//                         boardingPoint: "Neredmet",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "3A04",
//                         boardingPoint: "G.K colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "3A05",
//                         boardingPoint: "R.K Puram",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "3A06",
//                         boardingPoint: "Lothukunta Dharga",
//                         time: "8:00"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4",
//                 busName: "Charlapally",
//                 driver: "A.Ramesh",
//                 driverNo: 9491868404,
//                 routeAndTimings: [{
//                         bpNo: "401",
//                         boardingPoint: "Charlapally(EC Nagar Kaman)",
//                         time: "7:15"
//                     },
//                     {
//                         bpNo: "402",
//                         boardingPoint: "ICOM Bus Stop",
//                         time: "7:20"
//                     },
//                     {
//                         bpNo: "403",
//                         boardingPoint: "Ashok Nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "404",
//                         boardingPoint: "HB Colony NTR Statue",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "405",
//                         boardingPoint: "HB Colony E-Seva",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "406",
//                         boardingPoint: "Moulaali",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "407",
//                         boardingPoint: "Lothukunta HDFC Bank",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "408",
//                         boardingPoint: "Kompally",
//                         time: "8:15"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4A",
//                 busName: "ZTS",
//                 driver: "UdayKiran",
//                 driverNo: 9052098540,
//                 routeAndTimings: [{
//                         bpNo: "4A01",
//                         boardingPoint: "ZTS CocaCola",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A02",
//                         boardingPoint: "NMDC",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A03",
//                         boardingPoint: "East Anand Bagh",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A04",
//                         boardingPoint: "Anand bagh X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A05",
//                         boardingPoint: "Shafilguda X Road",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A06",
//                         boardingPoint: "Krupa Complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A07",
//                         boardingPoint: "Vinayak Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "4A08",
//                         boardingPoint: "Brindavan Colony(Bollurum)",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5",
//                 busName: "ECIL",
//                 driver: "A.Sowjanya",
//                 driverNo: 9642754545,
//                 routeAndTimings: [{
//                         bpNo: "501",
//                         boardingPoint: "ECIL X Road",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "502",
//                         boardingPoint: "Sharadha Hospital",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "503",
//                         boardingPoint: "Alwal Mee Seva",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "504",
//                         boardingPoint: "Ambedkar Statue",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "505",
//                         boardingPoint: "IG STatue",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "506",
//                         boardingPoint: "Narayana JR College",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "507",
//                         boardingPoint: "Loyola College",
//                         time: "8:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5A",
//                 busName: "ECIL",
//                 driver: "Padmapriya",
//                 driverNo: 8886567836,
//                 routeAndTimings: [{
//                         bpNo: "5A01",
//                         boardingPoint: "Sharada BusStop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "5A02",
//                         boardingPoint: "Vayupuri",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "5A03",
//                         boardingPoint: "Lothukunta Railway Bridge",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "5A04",
//                         boardingPoint: "Temple Alwal",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "5A05",
//                         boardingPoint: "More SuperMarket",
//                         time: "7:55"
//                     }
//                 ]
//             },
//             {
//                 busNo: "6",
//                 busName: "Lalapet",
//                 driver: "B.Sinduja",
//                 driverNo: 628110303,
//                 routeAndTimings: [{
//                         bpNo: "601",
//                         boardingPoint: "Lalapet Busstop",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "602",
//                         boardingPoint: "Shanti nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "603",
//                         boardingPoint: "Noori Panshop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "604",
//                         boardingPoint: "Mirjala Guda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "605",
//                         boardingPoint: "Sairam Theater",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "606",
//                         boardingPoint: "Anutex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "607",
//                         boardingPoint: "Uttam Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "608",
//                         boardingPoint: "Ghanshyam",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "609",
//                         boardingPoint: "Picket",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "610",
//                         boardingPoint: "Kharkhana",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "611",
//                         boardingPoint: "Tirumalagiri police station",
//                         time: "8:00"
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         cluster: "5",
//         clusterName: "Towards Bollarm, Alwal, ECIL",
//         busRoutes:[
//             {
//                 busNo: "1",
//                 busName: "Rampally X Road",
//                 driver: "NagaSulochana",
//                 driverNo: 8897190123,
//                 routeAndTimings: [{
//                         bpNo: "101",
//                         boardingPoint: "Rampally X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "102",
//                         boardingPoint: "Nagaram petrol bunk",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "103",
//                         boardingPoint: "Viajaya hospital",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "104",
//                         boardingPoint: "Chakripuram",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "105",
//                         boardingPoint: "Nagarjuna Colony",
//                         time: "7:50"
//                     },

//                 ]
//             },
//             {
//                 busNo: "1A",
//                 busName: "Kushaiguda",
//                 driver: "G.Laxmi",
//                 driverNo: 9963127930,
//                 routeAndTimings: [{
//                         bpNo: "1A01",
//                         boardingPoint: "Kushaiguda(DMart)",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "1A02",
//                         boardingPoint: "Shopping complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A03",
//                         boardingPoint: "Nirmal Nagar",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "1A04",
//                         boardingPoint: "Yapral water tank",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A05",
//                         boardingPoint: "Yapral Bus stop",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "1A06",
//                         boardingPoint: "Yapral(Militry Gate)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A07",
//                         boardingPoint: "Priyadharshini Enclave",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "1A08",
//                         boardingPoint: "Risala bazar",
//                         time: "8:05"
//                     },
//                 ]
//             },
//             {
//                 busNo: "2",
//                 busName: "Nagaram",
//                 driver: "Suguna",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "201",
//                         boardingPoint: "Nagaram Water Tank",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "202",
//                         boardingPoint: "Vayushakthi Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "203",
//                         boardingPoint: "P.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "204",
//                         boardingPoint: "Dammaiguda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "205",
//                         boardingPoint: "Sai Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "206",
//                         boardingPoint: "Saketh Town",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "207",
//                         boardingPoint: "Paremala Nagar",
//                         time: "7:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "2A",
//                 busName: "Saket",
//                 driver: "Sunil Kumar",
//                 driverNo: 9855590258,
//                 routeAndTimings: [{
//                         bpNo: "2A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "2A02",
//                         boardingPoint: "Jammaigadda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "2A03",
//                         boardingPoint: "Yella reddy guda",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A04",
//                         boardingPoint: "Dhabha garden",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A05",
//                         boardingPoint: "kapra Munsipal office",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "2A06",
//                         boardingPoint: "Radhika School",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A07",
//                         boardingPoint: "Nethaji nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "2A08",
//                         boardingPoint: "Kandhiguda",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "2A09",
//                         boardingPoint: "Kowkur darga",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A10",
//                         boardingPoint: "Janapriya",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "2A11",
//                         boardingPoint: "Kowkur BusStop",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "2A12",
//                         boardingPoint: "Hakimpet",
//                         time: "8:05"
//                     },
//                     {
//                         bpNo: "2A13",
//                         boardingPoint: "Yamjal(Telangana Paper)",
//                         time: "8:10"
//                     },
//                 ]
//             },
//             {
//                 busNo: "3",
//                 busName: "Radhika Bus Stop",
//                 driver: "ES Babu",
//                 driverNo: 9989148496,
//                 routeAndTimings: [{
//                         bpNo: "301",
//                         boardingPoint: "Radhika",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "301",
//                         boardingPoint: "Lal Bazar(Petrol Bunk)",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "303",
//                         boardingPoint: "Teachers Colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "304",
//                         boardingPoint: "Kanajiguda",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "305",
//                         boardingPoint: "Green Fields(DMart)",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "306",
//                         boardingPoint: "ST. Anns School",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "307",
//                         boardingPoint: "Diary Form",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "3A",
//                 busName: "Kushaiguda Bus stop",
//                 driver: "P.Sirisha",
//                 driverNo: 995988325,
//                 routeAndTimings: [{
//                         bpNo: "3A01",
//                         boardingPoint: "Saket colony",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "3A02",
//                         boardingPoint: "A.S Rao Nagar",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "3A03",
//                         boardingPoint: "Neredmet",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "3A04",
//                         boardingPoint: "G.K colony",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "3A05",
//                         boardingPoint: "R.K Puram",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "3A06",
//                         boardingPoint: "Lothukunta Dharga",
//                         time: "8:00"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4",
//                 busName: "Charlapally",
//                 driver: "A.Ramesh",
//                 driverNo: 9491868404,
//                 routeAndTimings: [{
//                         bpNo: "401",
//                         boardingPoint: "Charlapally(EC Nagar Kaman)",
//                         time: "7:15"
//                     },
//                     {
//                         bpNo: "402",
//                         boardingPoint: "ICOM Bus Stop",
//                         time: "7:20"
//                     },
//                     {
//                         bpNo: "403",
//                         boardingPoint: "Ashok Nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "404",
//                         boardingPoint: "HB Colony NTR Statue",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "405",
//                         boardingPoint: "HB Colony E-Seva",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "406",
//                         boardingPoint: "Moulaali",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "407",
//                         boardingPoint: "Lothukunta HDFC Bank",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "408",
//                         boardingPoint: "Kompally",
//                         time: "8:15"
//                     }
//                 ]
//             },
//             {
//                 busNo: "4A",
//                 busName: "ZTS",
//                 driver: "UdayKiran",
//                 driverNo: 9052098540,
//                 routeAndTimings: [{
//                         bpNo: "4A01",
//                         boardingPoint: "ZTS CocaCola",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A02",
//                         boardingPoint: "NMDC",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "4A03",
//                         boardingPoint: "East Anand Bagh",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A04",
//                         boardingPoint: "Anand bagh X Road",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "4A05",
//                         boardingPoint: "Shafilguda X Road",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A06",
//                         boardingPoint: "Krupa Complex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "4A07",
//                         boardingPoint: "Vinayak Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "4A08",
//                         boardingPoint: "Brindavan Colony(Bollurum)",
//                         time: "8:05"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5",
//                 busName: "ECIL",
//                 driver: "A.Sowjanya",
//                 driverNo: 9642754545,
//                 routeAndTimings: [{
//                         bpNo: "501",
//                         boardingPoint: "ECIL X Road",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "502",
//                         boardingPoint: "Sharadha Hospital",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "503",
//                         boardingPoint: "Alwal Mee Seva",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "504",
//                         boardingPoint: "Ambedkar Statue",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "505",
//                         boardingPoint: "IG STatue",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "506",
//                         boardingPoint: "Narayana JR College",
//                         time: "8:00"
//                     },
//                     {
//                         bpNo: "507",
//                         boardingPoint: "Loyola College",
//                         time: "8:50"
//                     }
//                 ]
//             },
//             {
//                 busNo: "5A",
//                 busName: "ECIL",
//                 driver: "Padmapriya",
//                 driverNo: 8886567836,
//                 routeAndTimings: [{
//                         bpNo: "5A01",
//                         boardingPoint: "Sharada BusStop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "5A02",
//                         boardingPoint: "Vayupuri",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "5A03",
//                         boardingPoint: "Lothukunta Railway Bridge",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "5A04",
//                         boardingPoint: "Temple Alwal",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "5A05",
//                         boardingPoint: "More SuperMarket",
//                         time: "7:55"
//                     }
//                 ]
//             },
//             {
//                 busNo: "6",
//                 busName: "Lalapet",
//                 driver: "B.Sinduja",
//                 driverNo: 628110303,
//                 routeAndTimings: [{
//                         bpNo: "601",
//                         boardingPoint: "Lalapet Busstop",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "602",
//                         boardingPoint: "Shanti nagar",
//                         time: "7:25"
//                     },
//                     {
//                         bpNo: "603",
//                         boardingPoint: "Noori Panshop",
//                         time: "7:30"
//                     },
//                     {
//                         bpNo: "604",
//                         boardingPoint: "Mirjala Guda",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "605",
//                         boardingPoint: "Sairam Theater",
//                         time: "7:35"
//                     },
//                     {
//                         bpNo: "606",
//                         boardingPoint: "Anutex",
//                         time: "7:40"
//                     },
//                     {
//                         bpNo: "607",
//                         boardingPoint: "Uttam Nagar",
//                         time: "7:45"
//                     },
//                     {
//                         bpNo: "608",
//                         boardingPoint: "Ghanshyam",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "609",
//                         boardingPoint: "Picket",
//                         time: "7:50"
//                     },
//                     {
//                         bpNo: "610",
//                         boardingPoint: "Kharkhana",
//                         time: "7:55"
//                     },
//                     {
//                         bpNo: "611",
//                         boardingPoint: "Tirumalagiri police station",
//                         time: "8:00"
//                     }
//                 ]
//             }
//         ]
//     },
// ]
// export default data;

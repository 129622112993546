import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import BusRoutes from "./pages/BusRoutes";
import LoginPage from "./pages/LoginPage";
import BusInfo from "./pages/BusInfo";
import BusRouteInfo from "./pages/BusRouteInfo";
import About from "./pages/About";
import TransportRequestForm from "./pages/TransportRequestForm";
import EditRoutes from "./pages/EditRoutes";
import SuperAdmin from "./pages/SuperAdmin";
import AdminPage from "./pages/AdminPage";
import Loader from "./components/Loader";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import useBus from "./customhooks/useBus";
import useCluster from "./customhooks/useCluster";
import useStops from "./customhooks/useStops";
import useStudent from "./customhooks/useStudent";
import ProtectedRoute from "./pages/ProtectedRoute";
import { singleStudentActions } from "./store/store";
import useStaff from "./customhooks/useStaff";

function LoginPageConditionalComponent() {
  const location = useLocation();
  const path = location.pathname;
  const temp = path.split("/");
  if (temp[1] === "adminStaff") {
    if (temp[2] === "cmrtc") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrtc.ac.in/wp-content/uploads/2023/01/logo.webp"
            }
            college="cmrtc"
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrtc.ac.in/wp-content/uploads/2023/01/logo.webp"
          }
          college="cmrtc"
          role={"adminStaff"}
        />
      );
    } else if (temp[2] === "cmrec") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://s3-ap-south-1.amazonaws.com/cmrec/wp-media-folder-cmr-engineering-college-hyderabad-telangana-india-approved-by-aicte-new-delhi-and-affiliated-to-jnt-university-hyderabad/wp-content/uploads/2021/08/CMR-Main-Logo-Final.png"
            }
            college="cmrec"
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://s3-ap-south-1.amazonaws.com/cmrec/wp-media-folder-cmr-engineering-college-hyderabad-telangana-india-approved-by-aicte-new-delhi-and-affiliated-to-jnt-university-hyderabad/wp-content/uploads/2021/08/CMR-Main-Logo-Final.png"
          }
          college="cmrec"
          role={"adminStaff"}
        />
      );
    } else if (temp[2] === "cmrcet") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrcet.ac.in/wp-content/themes/cmr/img/logo4.jpg"
            }
            college="cmrcet"
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrcet.ac.in/wp-content/themes/cmr/img/logo4.jpg"
          }
          college="cmrcet"
          role={"adminStaff"}
        />
      );
    } else if (temp[2] === "cmrit") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://i0.wp.com/cmrithyderabad.edu.in/wp-content/uploads/2021/09/cropped-CMR-IT-logo-1.webp?w=731&ssl=1"
            }
            college="cmrit"
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://i0.wp.com/cmrithyderabad.edu.in/wp-content/uploads/2021/09/cropped-CMR-IT-logo-1.webp?w=731&ssl=1"
          }
          college="cmrit"
          role={"adminStaff"}
        />
      );
    } else if (temp[2] == "cmrcp") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={"https://cmrcp.ac.in/img/logo.jpg"}
            college={"cmrcp"}
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={"https://cmrcp.ac.in/img/logo.jpg"}
          college={"cmrcp"}
          role={"adminStaff"}
        />
      );
    } else if (temp[2] === "cmrims") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
            }
            college={"cmrims"}
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
          }
          college={"cmrims"}
          role={"adminStaff"}
        />
      );
    } else if (temp[2] === "cmrihs") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
            }
            college={"cmrihs"}
            role={"adminStaff"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
          }
          college={"cmrihs"}
          role={"adminStaff"}
        />
      );
    }
  } else if (temp[1] === "admin") {
    if (temp[2] === "cmrtc") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrtc.ac.in/wp-content/uploads/2023/01/logo.webp"
            }
            college="cmrtc"
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrtc.ac.in/wp-content/uploads/2023/01/logo.webp"
          }
          college="cmrtc"
          role={"admin"}
        />
      );
    } else if (temp[2] === "cmrec") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://s3-ap-south-1.amazonaws.com/cmrec/wp-media-folder-cmr-engineering-college-hyderabad-telangana-india-approved-by-aicte-new-delhi-and-affiliated-to-jnt-university-hyderabad/wp-content/uploads/2021/08/CMR-Main-Logo-Final.png"
            }
            college="cmrec"
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://s3-ap-south-1.amazonaws.com/cmrec/wp-media-folder-cmr-engineering-college-hyderabad-telangana-india-approved-by-aicte-new-delhi-and-affiliated-to-jnt-university-hyderabad/wp-content/uploads/2021/08/CMR-Main-Logo-Final.png"
          }
          college="cmrec"
          role={"admin"}
        />
      );
    } else if (temp[2] === "cmrcet") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrtc.ac.in/wp-content/uploads/2023/01/logo.webp"
            }
            college="cmrcet"
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrcet.ac.in/wp-content/themes/cmr/img/logo4.jpg"
          }
          college="cmrcet"
          role={"admin"}
        />
      );
    } else if (temp[2] === "cmrit") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://i0.wp.com/cmrithyderabad.edu.in/wp-content/uploads/2021/09/cropped-CMR-IT-logo-1.webp?w=731&ssl=1"
            }
            college="cmrit"
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://i0.wp.com/cmrithyderabad.edu.in/wp-content/uploads/2021/09/cropped-CMR-IT-logo-1.webp?w=731&ssl=1"
          }
          college="cmrit"
          role={"admin"}
        />
      );
    } else if (temp[2] == "cmrcp") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={"https://cmrcp.ac.in/img/logo.jpg"}
            college={"cmrcp"}
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={"https://cmrcp.ac.in/img/logo.jpg"}
          college={"cmrcp"}
          role={"admin"}
        />
      );
    } else if (temp[2] === "cmrims") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
            }
            college={"cmrims"}
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
          }
          college={"cmrims"}
          role={"admin"}
        />
      );
    } else if (temp[2] === "cmrihs") {
      if (temp[3] === "updatePassword") {
        return (
          <LoginPage
            collegeBanner={
              "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
            }
            college={"cmrihs"}
            role={"admin"}
            isUpdatePage={true}
          />
        );
      }
      return (
        <LoginPage
          collegeBanner={
            "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
          }
          college={"cmrihs"}
          role={"admin"}
        />
      );
    }
  } else if (temp[1] === "transportAdmin") {
    if (temp[2] === "updatePassword") {
      return (
        <LoginPage
          collegeBanner={
            "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
          }
          role={"transportAdmin"}
          college={"cmrgi"}
          isUpdatePage={true}
        />
      );
    }
    return (
      <LoginPage
        collegeBanner={
          "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
        }
        role={"transportAdmin"}
        college={"cmrgi"}
      />
    );
  } else if (temp[1] === "superAdmin") {
    if (temp[2] === "updatePassword") {
      return (
        <LoginPage
          collegeBanner={
            "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
          }
          role={"superAdmin"}
          college={"cmrgi"}
          isUpdatePage={true}
        />
      );
    }
    return (
      <LoginPage
        collegeBanner={
          "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"
        }
        role={"superAdmin"}
        college={"cmrgi"}
      />
    );
  }
  // if(temp[2] === "cmrtc"){
  //   return <LoginPage collegeBanner={"https://cmrtc.ac.in/wp-content/uploads/2023/01/logo.webp"} college="cmrtc"/>
  // }
  // else if(temp[2] === "cmrec"){
  //   return <LoginPage collegeBanner={"https://s3-ap-south-1.amazonaws.com/cmrec/wp-media-folder-cmr-engineering-college-hyderabad-telangana-india-approved-by-aicte-new-delhi-and-affiliated-to-jnt-university-hyderabad/wp-content/uploads/2021/08/CMR-Main-Logo-Final.png"} college="cmrec"/>
  // }
  // else if(temp[2] === "cmrcet"){
  //   return <LoginPage collegeBanner={"https://s3-alpha-sig.figma.com/img/8a1b/6e1d/4445438a6d96a133679e89c608117d3a?Expires=1721001600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Qr9be2bKWVQsQCfBqdlfrhG~7w7AxgAkoaQXZejowVAFb3SqasvVZZA8wkPh18VIEzuuTyuhdmB1GY6fpFIXgax72CjmLgAN~V81feEdbVV-t4uPYSz0lLSQeM6HsBWC30Io1Z20jq4-8rUeC7rXiRMCkJv57P3CBXm17zyUghKoe-pzztJVCWrMqEauxXci4Pe2JWYrD6pyPc7yee9dtvPjf01yBaVaBn3o9PxaC9s8uhe9g7h6~dxAq1WihiDkQy-~NrCnj95ojq0v9~aUfBI~EIk8ntul-maTJ6x9MmnOj6zsnvyy5wU3k7L8rXoHSkHNkn5Uz5MYhvsb~wXt0g__"} college="cmrcet"/>
  // }
  // else if(temp[2] === "cmrit"){
  //   return <LoginPage collegeBanner={"https://i0.wp.com/cmrithyderabad.edu.in/wp-content/uploads/2021/09/cropped-CMR-IT-logo-1.webp?w=731&ssl=1"} college="cmrit"/>
  // }
  // else if(temp[2] == "cmrcp"){
  //   return <LoginPage collegeBanner={"https://cmrcp.ac.in/img/logo.jpg"} college={"cmrcp"} />
  // }
  // else if(temp[2] == "cmrgi"){
  //   return <LoginPage collegeBanner={"https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"} college={"cmrgi"} />
  // }
  // else if(temp[2] === "cmrims"){
  //   return <LoginPage collegeBanner={"https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"} college={"cmrims"} />
  // }
  // else if(temp[2] === "cmrihs"){
  //   return <LoginPage collegeBanner={"https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"} college={"cmrihs"} />
  // }
  // else if(temp[1] === "transportAdmin"){
  //   return <LoginPage collegeBanner={"https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"} college={"transportAdmin"} />
  // }
}

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/about", element: <About /> },
  { path: "/busRoutes", element: <BusRoutes />, caseSensitive: true },
  { path: "/busRoutes/:id", element: <BusInfo /> },
  { path: "/adminRoute/editRoutes", element: <EditRoutes /> },
  {
    path: "/adminStaff/cmrtc/login",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrec/login",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrcet/login",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrit/login",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrcp/login",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrims/login",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrihs/login",
    element: <LoginPageConditionalComponent />,
  },
  { path: "/admin/cmrtc/login", element: <LoginPageConditionalComponent /> },
  { path: "/admin/cmrec/login", element: <LoginPageConditionalComponent /> },
  { path: "/admin/cmrcet/login", element: <LoginPageConditionalComponent /> },
  { path: "/admin/cmrit/login", element: <LoginPageConditionalComponent /> },
  { path: "/admin/cmrcp/login", element: <LoginPageConditionalComponent /> },
  { path: "/admin/cmrims/login", element: <LoginPageConditionalComponent /> },
  { path: "/admin/cmrihs/login", element: <LoginPageConditionalComponent /> },
  { path: "/superAdmin/login", element: <LoginPageConditionalComponent /> },
  { path: "/transportAdmin/login", element: <LoginPageConditionalComponent /> },

  {
    path: "/adminStaff/cmrtc/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrec/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrcet/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrit/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrcp/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrims/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/adminStaff/cmrihs/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrtc/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrec/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrcet/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrit/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrcp/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrims/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/admin/cmrihs/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/superAdmin/updatePassword",
    element: <LoginPageConditionalComponent />,
  },
  {
    path: "/transportAdmin/updatePassword",
    element: <LoginPageConditionalComponent />,
  },

  {
    path: "/adminStaff/cmrtc/home",
    element: (
      <ProtectedRoute college={"cmrtc"} role="adminStaff">
        <AdminPage admin="false" college="cmrtc" role="adminStaff" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/adminStaff/cmrec/home",
    element: (
      <ProtectedRoute college={"cmrce"} role="adminStaff">
        <AdminPage admin="false" college="cmrec" role="adminStaff" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/adminStaff/cmrcet/home",
    element: (
      <ProtectedRoute college={"cmrcet"} role="adminStaff">
        <AdminPage admin="false" college="cmrcet" role="adminStaff" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/adminStaff/cmrit/home",
    element: (
      <ProtectedRoute college={"cmrit"} role="adminStaff">
        <AdminPage admin="false" college="cmrit" role="adminStaff" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/adminStaff/cmrcp/home",
    element: (
      <ProtectedRoute college={"cmrcp"} role="adminStaff">
        <AdminPage admin="false" college="cmrcp" role="adminStaff" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/adminStaff/cmrims/home",
    element: (
      <ProtectedRoute college={"cmrims"} role="adminStaff">
        <AdminPage admin="false" college="cmrims" role="adminStaff" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/adminStaff/cmrihs/home",
    element: (
      <ProtectedRoute college={"cmrihs"} role="adminStaff">
        <AdminPage admin="false" college="cmrihs" role="adminStaff" />
      </ProtectedRoute>
    ),
  },

  {
    path: "/admin/cmrtc/home",
    element: (
      <ProtectedRoute college={"cmrtc"} role="admin">
        <AdminPage admin="true" college="cmrtc" role="admin" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/cmrec/home",
    element: (
      <ProtectedRoute college={"cmrce"} role="admin">
        <AdminPage admin="true" college="cmrec" role="admin" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/cmrcet/home",
    element: (
      <ProtectedRoute college={"cmrcet"} role="admin">
        <AdminPage admin="true" college="cmrcet" role="admin" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/cmrit/home",
    element: (
      <ProtectedRoute college={"cmrit"} role="admin">
        <AdminPage admin="true" college="cmrit" role="admin" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/cmrcp/home",
    element: (
      <ProtectedRoute college={"cmrcp"} role="admin">
        <AdminPage admin="true" college="cmrcp" role="admin" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/cmrims/home",
    element: (
      <ProtectedRoute college={"cmrims"} role="admin">
        <AdminPage admin="true" college="cmrims" role="admin" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/cmrihs/home",
    element: (
      <ProtectedRoute college={"cmrihs"} role="admin">
        <AdminPage admin="true" college="cmrihs" role="admin" />
      </ProtectedRoute>
    ),
  },

  {
    path: "adminStaff/cmrit/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrit" role="adminStaff">
        <TransportRequestForm college="cmrit" />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrcet/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrcet" role="adminStaff">
        <TransportRequestForm college="cmrcet" />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrec/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrec" role="adminStaff">
        <TransportRequestForm college="cmrec" />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrcp/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrcp" role="adminStaff">
        <TransportRequestForm college="cmrcp" />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrtc/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrtc" role="adminStaff">
        <TransportRequestForm college="cmrtc" />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrims/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrims" role="adminStaff">
        <TransportRequestForm college="cmrims" />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrihs/home/studentRegistrationForm",
    element: (
      <ProtectedRoute college="cmrihs" role="adminStaff">
        <TransportRequestForm college="cmrihs" />
      </ProtectedRoute>
    ),
  },

  {
    path: "adminStaff/cmrit/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrit" role="adminStaff">
        <TransportRequestForm college="cmrit" teacher={true} />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrcet/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrcet" role="adminStaff">
        <TransportRequestForm college="cmrcet" teacher={true} />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrec/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrec" role="adminStaff">
        <TransportRequestForm college="cmrec" teacher={true} />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrcp/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrcp" role="adminStaff">
        <TransportRequestForm college="cmrcp" teacher={true} />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrtc/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrtc" role="adminStaff">
        <TransportRequestForm college="cmrtc" teacher={true} />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrims/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrims" role="adminStaff">
        <TransportRequestForm college="cmrims" teacher={true} />
      </ProtectedRoute>
    ),
  },
  {
    path: "adminStaff/cmrihs/home/staffRegistrationForm",
    element: (
      <ProtectedRoute college="cmrihs" role="adminStaff">
        <TransportRequestForm college="cmrihs" teacher={true} />
      </ProtectedRoute>
    ),
  },

  {
    path: "superAdmin/home",
    element: (
      <ProtectedRoute college={"cmrgi"} role="superAdmin">
        <SuperAdmin admin={false} />
      </ProtectedRoute>
    ),
  },
  {
    path: "transportAdmin/home",
    element: (
      <ProtectedRoute college={"cmrgi"} role="transportAdmin">
        <SuperAdmin admin={true} />
      </ProtectedRoute>
    ),
  },
  { path: "/busRoutes/bus/:clusterid/:id", element: <BusRouteInfo /> },
  // {path: "/edit", element: <SuperAdmin />},
]);

function App() {
  const { fetchBuses } = useBus();
  const { fetchClusters } = useCluster();
  const { fetchStops } = useStops();

  useEffect(() => {
    fetchStops();
    fetchBuses();
    fetchClusters();
  }, []);

  const loading = useSelector((state) => state.busState.loading);
  const loading2 = useSelector((state) => state.stopState.loading);
  const loading3 = useSelector((state) => state.clusterState.loading);
  const loading4 = useSelector((state) => state.studentState.loading);
  const loading5 = useSelector((state) => state.adminState.loading);

  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
      {(loading || loading2 || loading3 || loading4 || loading5) && <Loader />}
    </>
  );
}

export default App;

import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useStudent from "../customhooks/useStudent";
import ShowStudentDetails from "./ShowStudentDetails";
import Modal from "./Modal";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import useBus from "../customhooks/useBus";
import useStops from "../customhooks/useStops";
import { useReactToPrint } from "react-to-print";
import Images from "../components/Images";
import Image from "../images/BannerImage.png";
import * as XLSX from "xlsx";
import useCluster from "../customhooks/useCluster";
import useStaff from "../customhooks/useStaff";

function Student({ adminPage, collegeSent, viewOnly, teacher = false }) {
  const pdfComponent = useRef();
  const headings = [
    "SNo",
    "rollNo",
    "college",
    "department",
    "studentName",
    "routeNo",
    "stage",
    "phoneNumber",
  ];
  let unsortedStudents = useSelector((state) =>
    teacher ? state.staffState.tempStaff : state.studentState.tempStudents
  );
  const departmentsOrder = [
    "CSE",
    "CSM",
    "CSD",
    "AIML",
    "CSG",
    "IT",
    "ECE",
    "EEE",
    "CIVIL",
    "MECHANICAL",
    "MBA",
  ];
  const collegeOrder = [
    "cmrcet",
    "cmrit",
    "cmrtc",
    "cmrec",
    "cmrcp",
    "cmrims",
    "cmrihs",
  ];
  const students = [...unsortedStudents]
    .sort(function (a, b) {
      return collegeOrder.indexOf(a.college) - collegeOrder.indexOf(b.college);
    })
    .sort(function (a, b) {
      if (a.college === b.college) {
        return (
          departmentsOrder.indexOf(a.department) -
          departmentsOrder.indexOf(b.department)
        );
      }
      return 0;
    });
  const { fetchBuses } = useBus();
  const { fetchClusters } = useCluster();
  const { fetchStops } = useStops();
  const { fetchStudents, searchStudentsByName } = useStudent();
  const { convertIdToStop } = useStops();
  const { converIdToBus } = useBus();
  const { fetchStaff, searchStaffByName } = useStaff();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchStops();
    fetchBuses();
    fetchClusters();
    fetchStudents();
    fetchStaff();
  }, []);

  useEffect(() => {
    if (teacher) {
      searchStaffByName(searchText, collegeSent);
    } else {
      searchStudentsByName(searchText, collegeSent);
    }
  }, [searchText]);

  const handlePdfGeneration = useReactToPrint({
    content: () => pdfComponent.current,
    documentTitle: "userData",
  });

  const handleExcelGeneration = () => {
    let studentData = [];
    let count = 1;
    for (const student of students) {
      const busData = converIdToBus(student["routeNo"][0])[0];
      const routeNo = busData ? busData["busName"] : "";
      const stageData = convertIdToStop(student["stage"][0])[0];
      const stage = stageData ? stageData["boardingPoint"] : "";
      const singleRecord = {};
      if (students.length !== 0) {
        for (const heading of Object.keys(students[0])) {
          if (heading === "_id") singleRecord["SNo"] = count++;
          else if (heading === "__v") continue;
          else if (heading === "routeNo") singleRecord["route"] = routeNo;
          else if (heading === "stage") singleRecord["stage"] = stage;
          else singleRecord[heading] = student[heading];
        }
        studentData.push(singleRecord);
      }
    }

    var workBook = XLSX.utils.book_new(),
      workSheet = XLSX.utils.json_to_sheet(studentData);

    XLSX.utils.book_append_sheet(workBook, workSheet, "StudentDataSheet1");
    XLSX.writeFile(workBook, "StudentData.xlsx");
  };

  const handleSearchBar = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <div>
      {!viewOnly ? (
        <Filter
          adminPage={adminPage}
          collegeSent={collegeSent}
          teacher={teacher}
        >
          <input
            className="regNameInput"
            type="text"
            name="searchText"
            value={searchText}
            onChange={handleSearchBar}
            placeholder={
              teacher ? "Enter Staff To Search" : "Enter Students To Search"
            }
            style={{ margin: "10px" }}
          />
          {students && students.length === 0 ? (
            <h1 style={{ textAlign: "center", padding: "10px" }}>
              No {teacher ? "Staff" : "Students"} with this filter
            </h1>
          ) : (
            <>
              <div className="busOperations">
                <div
                  className="buttons"
                  style={{
                    color: "green",
                    padding: "5px 30px",
                    borderRadius: "5px",
                  }}
                  onClick={handlePdfGeneration}
                >
                  Export to PDF
                </div>
                <div
                  className="buttons"
                  style={{
                    color: "green",
                    padding: "5px 30px",
                    borderRadius: "5px",
                  }}
                  onClick={handleExcelGeneration}
                >
                  Export to Excel
                </div>
              </div>
              <div className="busTable" style={{ margin: "20px 0px" }}>
                <table className="">
                  <thead>
                    <tr className="row">
                      {headings.map((heading, index) => (
                        <th
                          key={index}
                          style={{
                            fontSize: "20px",
                            textAlign: "left",
                            justifyContent: "flex-start",
                          }}
                        >
                          {heading[0].toUpperCase() +
                            heading.slice(1, heading.length)}
                        </th>
                      ))}
                      <th
                        style={{
                          fontSize: "20px",
                          textAlign: "left",
                          justifyContent: "flex-start",
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      students.map((student, index) => (
                        <StudentTableRow
                          studentDetails={student}
                          index={index}
                          studentHeading={headings}
                          key={index}
                          teacher={teacher}
                          college={collegeSent}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Filter>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid black",
              borderRadius: "5px",
              padding: "20px",
            }}
          >
            <h1 style={{ textAlign: "center" }}>Students Data</h1>
            <input
              className="regNameInput"
              type="text"
              name="searchText"
              value={searchText}
              onChange={handleSearchBar}
              placeholder="Enter Students To Search"
              style={{ margin: "10px" }}
            />
            {students && students.length === 0 ? (
              <h1 style={{ textAlign: "center", padding: "10px" }}>
                No {teacher ? "Staff" : "Students"} with this filter
              </h1>
            ) : (
              <>
                <div className="busOperations">
                  <div
                    className="buttons"
                    style={{
                      color: "green",
                      padding: "5px 30px",
                      borderRadius: "5px",
                    }}
                    onClick={handlePdfGeneration}
                  >
                    Export to PDF
                  </div>
                  <div
                    className="buttons"
                    style={{
                      color: "green",
                      padding: "5px 30px",
                      borderRadius: "5px",
                    }}
                    onClick={handleExcelGeneration}
                  >
                    Export to Excel
                  </div>
                </div>
                <div className="busTable" style={{ margin: "20px 0px" }}>
                  <table className="">
                    <thead>
                      <tr className="row">
                        {headings.map((heading, index) => (
                          <th
                            key={index}
                            style={{
                              fontSize: "20px",
                              textAlign: "left",
                              justifyContent: "flex-start",
                            }}
                          >
                            {heading[0].toUpperCase() +
                              heading.slice(1, heading.length)}
                          </th>
                        ))}
                        <th
                          style={{
                            fontSize: "20px",
                            textAlign: "left",
                            justifyContent: "flex-start",
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {students &&
                        students.map((student, index) => (
                          <StudentTableRow
                            studentDetails={student}
                            index={index}
                            studentHeading={headings}
                            key={index}
                            college={collegeSent}
                            teacher={teacher}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div style={{ display: "none" }}>
        <PrintComponent
          ref={pdfComponent}
          students={students}
          headings={headings}
        />
      </div>
    </div>
  );
}

function StudentTableRow({
  studentDetails,
  index,
  studentHeading,
  college,
  teacher,
}) {
  const { converIdToBus } = useBus();
  const { convertIdToStop } = useStops();
  const busData = converIdToBus(studentDetails["routeNo"][0])[0];
  const routeNo = busData ? busData["busName"] : "";
  const stageData = convertIdToStop(studentDetails["stage"][0])[0];
  const stage = stageData ? stageData["boardingPoint"] : "";
  // const serverUrl = "https://us-central1-cmrgi-transport.cloudfunctions.net/api/"
  // const serverUrl = Url
  // console.log(studentDetails["file"])
  // console.log(studentDetails)

  const studentDetailsShowData = {};
  for (const heading of Object.keys(studentDetails)) {
    if (heading === "__v" || heading === "file" || heading === "college")
      continue;
    else if (heading === "routeNo")
      studentDetailsShowData["routeNo"] = studentDetails["routeNo"][0];
    else if (heading === "stage")
      studentDetailsShowData["stage"] = studentDetails["stage"][0];
    else studentDetailsShowData[heading] = studentDetails[heading];
  }
  const [editOpen, setEditOpen] = useState(false);
  return (
    <>
      <tr className="row" key={index}>
        {studentHeading.map((heading) => (
          <td
            key={heading}
            style={{
              fontSize: "16px",
              textAlign: "left",
              justifyContent: "flex-start",
            }}
          >
            {heading === "SNo"
              ? index + 1
              : heading === "routeNo" || heading === "stage"
              ? heading === "routeNo"
                ? routeNo.toUpperCase()
                : stage.toUpperCase()
              : studentDetails[heading].toUpperCase()}
          </td>
        ))}
        <td>
          <div
            className="buttons"
            onClick={() => setEditOpen(true)}
            style={{ color: "black" }}
          >
            <DoubleArrowIcon sx={{ fontSize: 25 }} />
          </div>
        </td>
        <Modal open={editOpen} onClose={() => setEditOpen(false)}>
          <ShowStudentDetails
            studentDetails={studentDetailsShowData}
            show={false}
            photo={studentDetails["file"]}
            editOrDelete={true}
            submit={() => setEditOpen(false)}
            college={college}
            teacher={teacher}
          />
        </Modal>
      </tr>
    </>
  );
}

const PrintComponent = React.forwardRef(({ students, headings }, ref) => {
  return (
    <div ref={ref} style={{ width: "100%", padding: "5px" }}>
      <div className="Banner" style={{ padding: "30px" }}>
        <Images
          imageLink={Image}
          imageText={"CMRGI Banner"}
          styles={{ height: "120px" }}
        />
      </div>
      <table className="">
        <thead>
          <tr className="row">
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
              >
                {heading[0].toUpperCase() + heading.slice(1, heading.length)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {students &&
            students.map((student, index) => (
              <PrintComponentRow
                studentDetails={student}
                index={index}
                studentHeading={headings}
                key={index}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
});

function PrintComponentRow({ studentDetails, index, studentHeading }) {
  const { converIdToBus } = useBus();
  const { convertIdToStop } = useStops();
  const busData = converIdToBus(studentDetails["routeNo"][0])[0];
  const routeNo = busData ? busData["busName"] : "";
  const stageData = convertIdToStop(studentDetails["stage"][0])[0];
  const stage = stageData ? stageData["boardingPoint"] : "";

  return (
    <>
      <tr className="row" key={index}>
        {studentHeading.map((heading) => (
          <td
            key={heading}
            style={{
              fontSize: "10px",
              textAlign: "left",
              justifyContent: "flex-start",
            }}
          >
            {heading === "SNo"
              ? index + 1
              : heading === "routeNo" || heading === "stage"
              ? heading === "routeNo"
                ? routeNo.toUpperCase()
                : stage.toUpperCase()
              : studentDetails[heading].toUpperCase()}
          </td>
        ))}
      </tr>
    </>
  );
}

function Filter({ children, adminPage, collegeSent, teacher }) {
  const colleges = [
    "cmrtc",
    "cmrcet",
    "cmrit",
    "cmrec",
    "cmrcp",
    "cmrims",
    "cmrihs",
  ];
  const [departments, setDepartments] = useState([
    "CSM",
    "AIML",
    "CSE",
    "CSD",
    "CSG",
    "IT",
    "ECE",
    "MBA",
    "CSIT",
  ]);
  const years = teacher
    ? ["Teaching", "Non-Teaching", "Bus-Incharges"]
    : ["First", "Second", "Third", "Fourth"];
  const routes = useSelector((state) => state.busState.buses);
  // const message = useSelector((state) => state.studentState.message)
  const [formData, setFormData] = useState({
    college: adminPage ? collegeSent : "",
    department: "",
    year: "",
    routeNo: "",
  });
  const { getStudentByFilter } = useStudent();
  const { getStaffByFilter } = useStaff();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "college") {
      const college = value;
      if (college === "cmrec") {
        setDepartments(["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE"]);
      } else if (college === "cmrcet") {
        setDepartments([
          "CSM",
          "AIML",
          "CSE",
          "CSD",
          "CSG",
          "IT",
          "ECE",
          "EEE",
          "MECHANICAL",
          "CIVIL",
          "MBA",
        ]);
      } else if (college === "cmrims") {
        setDepartments(["MBBS"]);
      } else if (college === "cmrihs") {
        setDepartments(["NURSING", "BPT", "MLT", "ALLIED HEALTH SCIENCES"]);
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    teacher ? getStaffByFilter(formData) : getStudentByFilter(formData);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData)
    teacher ? getStaffByFilter(formData) : getStudentByFilter(formData);
  };

  return (
    <div
      className="Wrapper"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <form
        className="form registerForm"
        onSubmit={handleSubmit}
        style={{ width: "95%" }}
      >
        <div className="container">
          <h1>{teacher ? "Get Staff Data" : "Get Student Data"}</h1>
        </div>
        <div className="inputDataFieldsMultiple">
          <div className="inputDataFieldsSingle below">
            <h3>Campus :</h3>
            {adminPage ? (
              <select
                name="college"
                value={formData.college}
                onChange={handleChange}
              >
                <option value={collegeSent}>{collegeSent}</option>
              </select>
            ) : (
              <select
                name="college"
                value={formData.college}
                onChange={handleChange}
              >
                <option value="">Select College</option>
                {colleges.map((college, index) => (
                  <option key={index} value={college}>
                    {college.toUpperCase()}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="inputDataFieldsSingle below">
            <h3>Department:</h3>
            <select
              name="department"
              value={formData.department}
              onChange={handleChange}
            >
              <option value="">Select Department</option>
              {departments.map((dept, index) => (
                <option key={index} value={dept}>
                  {dept}
                </option>
              ))}
            </select>
          </div>
          <div className="inputDataFieldsSingle below">
            <h3>{teacher ? "Staff Type" : "Year"}:</h3>
            <select name="year" value={formData.year} onChange={handleChange}>
              <option value="">
                {teacher ? "Select Staff Type" : "Select Year"}
              </option>
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="inputDataFieldsSingle below">
            <h3>Bus:</h3>
            <select
              name="routeNo"
              value={formData.routeNo}
              onChange={handleChange}
            >
              <option value="">Select Bus No</option>
              {routes.map((route, index) => (
                <option key={index} value={route._id}>
                  {route.busNo}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button type="submit" id="regSubmit" style={{ width: "100px" }}>
          Submit
        </button>
        {children}
      </form>
    </div>
  );
}

export default Student;

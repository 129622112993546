import React, { useEffect } from "react";
import Header from "./../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import data from "../Data/data";
import Images from "./../components/Images";
import useBus from "../customhooks/useBus";
import useCluster from "../customhooks/useCluster";
import useStops from "../customhooks/useStops";
import useStudent from "../customhooks/useStudent";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function BusRouteInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlBus = location.pathname.split("/");
  const busLength = urlBus.length;
  const { fetchBuses, converIdToBus } = useBus();
  const { fetchClusters } = useCluster();
  const { fetchStops, convertIdToStop } = useStops();

  useEffect(() => {
    fetchStops();
    fetchBuses();
    fetchClusters();
  }, []);

  const clusters = useSelector((state) => state.clusterState.clusters);
  const arr = clusters.filter(
    (cluster) => cluster.cluster === urlBus[busLength - 2]
  );
  let buses = [];
  if (arr.length !== 0) {
    for (let i = 0; i < arr[0].busRoutes.length; i++) {
      // console.log(arr[0].busRoutes.length, arr[0].busRoutes)
      const busData = converIdToBus(arr[0].busRoutes[i]);
      if (busData.length === 0) continue;
      buses.push(busData[0]);
    }
  }
  // console.log(buses)
  const anotherArr = buses.filter((bus) => bus.busNo === urlBus[busLength - 1]);
  // console.log(anotherArr, anotherArr.length)
  let stops = [];
  if (anotherArr.length !== 0) {
    for (let i = 0; i < anotherArr[0].routeAndTimings.length; i++) {
      // console.log(anotherArr[0]["routeAndTimings"][i], convertIdToStop(anotherArr[0]["routeAndTimings"][i]))
      const stopData = convertIdToStop(anotherArr[0]["routeAndTimings"][i]);
      // console.log(stopData)
      if (stopData.length === 0) continue;
      stops.push([i + 1, stopData[0].boardingPoint, stopData[0].time]);
    }
  }

  function handleBackClick() {
    navigate(-1);
  }
  // console.log(stops)
  // const cluster = data.filter((elem) => elem.cluster === bus[busLength - 2])[0]
  // const busDetails = cluster.busRoutes.filter((elem) => elem.busNo === bus[busLength - 1])[0]
  // const routeDetails = busDetails.routeAndTimings.map((elem, index) => [index + 1, elem.boardingPoint, elem.time])
  return (
    <>
      <Header />
      <div
        className="backbutton"
        style={{
          backgroundColor: "#F6821F",
          width: "fit-content",
          borderRadius: "50%",
          padding: "5px",
          margin: "5px",
        }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon />
      </div>
      <div className="Busroutes">
        <h1 className="header">
          Route NO : {anotherArr[0].busNo} - {anotherArr[0].busName}
        </h1>
        <div className="main">
          <div className="Left">
            {stops.map((elem) => (
              <h3 key={elem[0]}>
                {elem[0]}.{elem[1]} - time : {elem[2]}
              </h3>
            ))}
          </div>
          {/* <div className="right">
            <Images imageLink={"https://s3-alpha-sig.figma.com/img/06b4/bb66/2562e7ca38cba73871a3c3add518ce0a?Expires=1721001600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=E~nCnNytfmuq9TLTWzM-jDM~USSFIfJrETzq9JePN2onbA~4vy542Q3i3dW7iBuIAoh7nhPH0qXpktBxWY13fsfxcyui~PhKAJrUaCdHsnq8HyTK6S47ZqLQcPifv4CoB7SrvTOtPeuwQk4rpwPqpdz0vsGcyvz5HHvtXcxIiR2ZA2bZ5gqVpaCi9OgnglMrzk2s~Oy0tcjqq2Do~yfAIAxhHhg6wTVTwWJDWAaYyKxaoO9JDfPMYgwU911puVuMiiOYHch~h0HGZvPTBRAmUWMsZiBbOSo2WTU6FE44z0J2Gys6WfE5RY4aE~qugtdA7PKP~ZZg7b7tkAMH1mo5GA__"} imageText={"RouteMap"}  />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BusRouteInfo;
